<template>
  <div>
    <a-form-model ref="formRef" :model="newData" :rules="rules">
      <a-form-model-item label="Name" prop="nama">
        <a-input
          size="large"
          class="w-100"
          :value="newData.nama"
          @change="(e) => handleChange(e.target.value, 'nama')"
        />
      </a-form-model-item>
      <a-form-model-item label="Alias" prop="alias">
        <a-input
          size="large"
          class="w-100"
          :value="newData.alias"
          @change="(e) => handleChange(e.target.value, 'alias')"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
const rules = {
  nama: [{ required: true, message: "Predicate's name is required!", trigger: 'blur' }],
  alias: [{ required: true, message: "Predicate's alias is required!", trigger: 'blur' }],
}
export default {
  props: {
    newData: {
      type: Object,
      required: true,
    },
    isSubmit: {
      type: Boolean,
    },
  },
  data() {
    return {
      rules,
    }
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
  watch: {
    isSubmit(newVal) {
      if (newVal) {
        this.$refs.formRef.validate(valid => {
          this.$emit('handle-validate', valid)
        })
      }
    },
  },
}
</script>

<style>
</style>
